<template>
    <div :style="{ padding: '36px 0px 16px 0px' }" class="filters-mobile-row">
      <sdButton
        class="float-right restart"
        size="small"
        type="danger"
        :outlined="true"
        @click="reset()"
      >
        <Icon class="icon-single">
          <sdFeatherIcons type="rotate-ccw" size="22" />
          <span>{{ $t("search_funds.filter_criteria.reset_filters") }}</span>
        </Icon>
      </sdButton>
    </div>
    <a-row>
      <etf-category-search />
    </a-row>
    <a-divider />
    <etfs-search-criteria></etfs-search-criteria>
    <a-row>
      <div class="search-button">
        <sdButton
          type="secondary"
          :outlined="false"
          class="filter-button"
          @click="populate(true)"
          :loading="isLoading"
        >
            {{ $t("search_funds.filter_criteria.categorical_filters") }}
        </sdButton>
      </div>
    </a-row>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import EtfCategorySearch from "@/components/wiseral/filters/EtfCategorySearch.vue";
import EtfsSearchCriteria from "@/components/wiseral/filters/EtfsSearchCriteria.vue";
import { useEtfsStore } from "../../../stores/EtfsStore";

const etfsStore = useEtfsStore();

defineProps({
    isLoading: {
      type: Boolean,
      default: false,
    },
});

const emit = defineEmits(["populate"]);

const populate = () => {
    emit("populate");
};

const reset = () => {
    etfsStore.resetData();
};
</script>

<style scoped lang="scss">
  .search-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .filter-button {
    border-radius: 2.5rem !important;
    width: 12rem;
    &.selected {
      border-color: black !important;
      color: black !important;
    }
  }
  .ant-divider {
    border-color: rgba(0, 0, 0, 0.8);
  }
  @media only screen and (max-width: 1149px) {
    .restart {
      position: absolute;
      margin-top: -70px;
      right: 20px;
    }

    .filters-mobile-row {
      margin-top: 70px;
    }
  }
</style>