<template>
    <a-row>
      <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('currency')">
        <label class="bold">{{
          $t("search_funds.funds_search_criteria.foreign_exchange")
        }}</label>
        <a-select
          id="busquedadivisas"
          v-model:value="etfsStore.data.currency"
          style="width: 90%; height: 45px"
          class="native-select"
          :token-separators="[',']"
          allowClear
        >
          <a-select-option
            v-for="(f, i) in divisas"
            :key="i + 'd'"
            :value="f.id"
            :title="f.name"
          >
            {{ f.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('inverse')">
        <label class="bold">{{
          $t("search_funds.funds_search_criteria.inverse")
        }}</label>
        <a-select
          id="cubierta"
          v-model:value="etfsStore.data.inverse"
          style="width: 90%; height: 45px"
          class="native-select"
          allowClear
        >
          <a-select-option
            v-for="(f, i) in cubiertas"
            :key="i + 'd'"
            :value="f === 'true'"
            :title="$t(`search_funds.funds_search_criteria.${f}`)"
          >
            {{ $t(`search_funds.funds_search_criteria.${f}`) }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :sm="3" :md="6" :xs="24" :lg="3" v-if="isActive('leverage')">
        <label class="bold">{{
          $t("search_funds.funds_search_criteria.leverage")
        }}</label>
        <a-select
          id="cubierta"
          v-model:value="etfsStore.data.leverage"
          style="width: 90%; height: 45px"
          class="native-select"
          allowClear
        >
          <a-select-option
            v-for="(f, i) in cubiertas"
            :key="i + 'd'"
            :value="f === 'true'"
            :title="$t(`search_funds.funds_search_criteria.${f}`)"
          >
            {{ $t(`search_funds.funds_search_criteria.${f}`) }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    
    <a-row class="mt-3" v-if="isActive('market_cap')">
      <a-space>
        <a-col>
          <span class="pt-3 d-block">{{
            $t("search_funds.funds_search_criteria.patrimony_higher_than")
          }}</span>
        </a-col>
        <a-col>
          <a-input
            class="mt-3"
            type="number"
            style="width: 80px"
            v-model:value="etfsStore.data.patrimony"
          ></a-input>
        </a-col>
        <a-col>
          <span class="pt-3 d-block">{{
            $t("search_funds.funds_search_criteria.million_e")
          }}</span>
        </a-col>
      </a-space>
    </a-row>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useEtfsStore } from "@/stores/EtfsStore";
import { useOperandStore } from "@/stores/OperandStore";
import { useAuthStore } from "@/stores/AuthStore";

const etfsStore = useEtfsStore();
const operandStore = useOperandStore();
const authStore = useAuthStore();

const divisas = computed(() => operandStore.etfCurrencies);
const cubiertas = computed(() => operandStore.yesNo);
const isActive = (key) =>
    !!authStore.config?.etfs_filtrable_columns?.general[key];

onMounted(async () => {
    operandStore.getEtfCurrencies();
});
</script>

<style scoped lang="scss">
label {
    margin: 10px;
}

:deep(.native-select) {
    border: 1px #000 solid;
    padding: 0 !important;
    margin: 0 !important;
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }

    &.ant-select-open {
      .ant-select-selector {
        border: none !important;
        height: 100% !important;
      }
    }
  }

.native_select {
    :deep(.ant-select-selector) {
      height: 45px !important;
    }
  }

.bold {
    margin-left: 0px;
}
</style>