import { defineStore } from "pinia";
import { ref } from "vue";
import QuickSearch from "@/models/QuickSearch";
// import axios from "axios"
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";
const quickSearch = new QuickSearch();

let abortController = new AbortController();
let abortControllerFamily = new AbortController();
let timeOut = null;

// const crypto = useCryptoStore()
export const useAssetByTickerStore = defineStore("useAssetByTickerStore", {
  state() {
    return {
      assetType: ref(""),
      error: null,
      only_my_bank: false,
      stocksList: {
        loading: false,
        data: [],
      },
    };
  },
  getters: {
    // stocks: async () => quickSearch.$find('stocks'),
    // cryptos: async () => quickSearch.$find('cryptos'),
  },
  actions: {
    async quicksearch(isin_tkr, type, only_my_bank = false) {
      try {
        abortController.abort();
        abortController = new AbortController();
        timeOut && clearTimeout(timeOut);
        timeOut = setTimeout(() => abortController.abort(), 100000);
        this.stocksList.loading = true;
        return client
          .get("/quicksearch", {
            params: { search: isin_tkr, type, only_my_bank: Number(only_my_bank) },
            signal: abortController.signal /* signal: newAbortSignal(5000)*/,
          })
          .then((response) => {
            this.stocksList.data = response.data.data;
            this.stocksList.loading = false;
            return response.data.data;
          })
          .finally(() => {
            clearTimeout(timeOut);
          });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getAssetsList(assets) {
      return quickSearch.whereIn("isin_tkr", assets).$all();
    },
    async getFamilyPositions(company = "") {
      abortControllerFamily.abort();
      abortControllerFamily = new AbortController();
      const authStore = useAuthStore();
      return client
        .get(`/family-positions`, {
          params: {
            company,
            month: authStore.config["number_months_to_show_positions"],
            only_my_bank: this.only_my_bank ? 1 : 0
          },
          signal:
            abortControllerFamily.signal /* signal: newAbortSignal(5000)*/,
        })
        .then((response) => {
          console.log(response);
          return response.data;
        });
    },
    async getAssetListByType(type, isin_tkr, only_my_bank) {
      if (!isin_tkr) {
        abortController.abort();
        timeOut && clearTimeout(timeOut);
        this.stocksList.loading = false;
        return;
      }
      switch (type) {
        case "funds":
          return this.quicksearch(isin_tkr, ["Fund"], only_my_bank);
        case "stocks":
          return this.quicksearch(isin_tkr, ["Stock"], only_my_bank);
        case "crypto":
          return this.quicksearch(isin_tkr, ["Crypto"], only_my_bank);
        case "benchmarks":
          return this.quicksearch(isin_tkr, ["Index", "Synthetic"], only_my_bank);
        case "etfs":
          return this.quicksearch(isin_tkr, ["ETF"], only_my_bank);
          case "pensions":
            return this.quicksearch(isin_tkr, ["Pension"], only_my_bank);  
        default:
          console.log("Invalid Security type");
          return [];
      }
    },
    clearList() {
      this.stocksList.data = [];
    },
    getFamilyPositionsFunds(family = [], months, position_name_id) {
      return client
        .get(`/family-positions/funds`, {
          params: { family, months, position_name_id, only_my_bank: this.only_my_bank ? 1 : 0 },
        })
        .then((response) => {
          console.log(response);
          return response.data;
        });
    },
  },
});
