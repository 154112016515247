<template>
    <a-col :sm="24" :xs="24">
      <a-row class="py-3">
        <a-col :xs="24" :sm="10">
          <a-select
            v-model:value="etfsStore.data.etfsTypes"
            style="width: 95%; height: 45px"
            :placeholder="$t('search_funds.filter_criteria_category.asset_type')"
            class="native-select"
          >
            <a-select-option
              v-for="i in theFundTypes"
              :key="i + 'd'"
              :value="i"
              :title="i"
            >
              {{ $t(`search_funds.filter_criteria_category.assets_type.${i}`, i) }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row class="py-3" v-if="inputsToShow.length > 0">
        <a-col
          v-for="(input, index) in inputsToShow"
          v-bind:key="index"
          :sm="6"
          class="mr-2"
        >
          <label for="">{{
            $t(`search_funds.filter_criteria_category.${input}`, input)
          }}</label>
          <a-select
            mode="multiple"
            show-search
            allowClear
            :token-separators="[',']"
            :filter-option="filterOption"
            v-model:value="etfsStore.data[etfsStore.mapConfigToModel(input)]"
            class="native-select"
            style="width: 95%"
            :options="getOptionsByInput(input)"
            :placeholder="
              $t(`search_funds.filter_criteria_category.${input}`, input)
            "
          >
          </a-select>
        </a-col>
      </a-row>
    </a-col>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useEtfsStore } from "@/stores/EtfsStore";
import { useOperandStore } from "@/stores/OperandStore";
import { useI18n } from "vue-i18n";

const authStore = useAuthStore();
const etfsStore = useEtfsStore();
const operandStore = useOperandStore();
const { t } = useI18n();

const inputByAsset = computed(
    () => authStore.config.etfs_filtrable_columns?.categoricall.fund_type || {}
);

const theFundTypes = computed(() => {
    return Object.keys(inputByAsset.value || {});
});

const whichInputShouldShow = (selectedAsset) => {
    return inputByAsset.value[selectedAsset];
};

const inputsToShow = computed(() =>
    etfsStore.data.etfsTypes
        ? whichInputShouldShow(etfsStore.data.etfsTypes)
        : {}
);

const countriesList = computed(() =>
    operandStore.countries.map((country) => ({
        value: country.id,
        label: t(`countries.${country.cat_name}`, country.cat_name),
    }))
);

const sectorsList = computed(() =>
    operandStore.sectors.map((sector) => ({
        value: sector.id,
        label: t(`sectors.${sector.name}`, sector.name),
    }))
);

const factorsList = computed(() =>
    operandStore.factors.map((item) => ({
        ...item,
        label: t(`factors.${item.label}`, item.label),
    }))
);

const sizesList = computed(() =>
    operandStore.sizes.map((item) => ({
        ...item,
        label: t(`sizes.${item.label}`, item.label),
    }))
);

const altTypeList = computed(() =>
    operandStore.altType.map((item) => ({
        ...item,
        label: t(`altTypes.${item.label}`, item.label),
    }))
);

const mixTypeList = computed(() =>
    operandStore.mixTypes.map((item) => ({
        ...item,
        label: t(`mixTypes.${item.label}`, item.label),
    }))
);

const termCatList = computed(() =>
    operandStore.termCat.map((item) => ({
        ...item,
        label: t(`termCats.${item.label}`, item.label),
    }))
);

const rentaRFList = computed(() =>
    operandStore.rentaRF.map((item) => ({
        ...item,
        label: t(`rentasRF.${item.label}`, item.label),
    }))
);

const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const getOptionsByInput = (type) => {
    switch (type) {
        case "country":
        return countriesList.value;
        case "sector":
        return sectorsList.value;
        case "factor":
        return factorsList.value;
        case "size":
        return sizesList.value;
        case "fi_type":
        return rentaRFList.value;
        case "term":
        return termCatList.value;
        case "mix_type":
        return mixTypeList.value;
        case "alternative_type":
        return altTypeList.value;
    }
    return [];
};

onMounted(() => {
    etfsStore.getEtfsTypes();
    operandStore.getCountries("etfs");
    operandStore.getSectors("etfs");
});
</script>

  <style scoped lang="scss">
  label {
    margin: 10px 0px;
  }

  @media only screen and (max-width: 1149px) {
    .mobile-input {
      min-width: 80vw;
    }
  }

  :deep(.native-select) {
    border: 1px #000 solid;
    padding: 0 !important;
    margin: 0 !important;
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }

    &.ant-select-open {
      .ant-select-selector {
        border: none !important;
        height: 100% !important;
      }
    }
  }
  </style>