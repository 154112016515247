<template>
  <a-modal
    v-model:visible="value"
    :title="!hasRename && $t('generic.titles.bests_modal_title', { total: funds.length })"
    type="primary"
    :footer="null"
    class="founds-modal"
  >
  <a-row v-if="hasRename">
      <h6>{{ $t("search_funds.rename_search") }}</h6>
      <div class="search-input-wrapper">
        <a-input
          v-model:value="searchName"
          size="small"
          style="width: 100%; border-color: #02B388 !important;"
        />
        <sdButton
          type="primary"
          style="height: 100%;"
          @click="saveSearch"
          :loading="isLoadingRename"
          >{{ $t("generic.buttons.ok") }}
        </sdButton>
      </div>
    </a-row>
    <h6 v-if="hasRename" class="mt-4">{{ $t('generic.titles.bests_modal_title', { total: funds.length }) }}</h6>
    <a-row gutter="8">
      <a-col :xs="24">
        <sdButton
          type="primary"
          class="mt-2"
          style="width: 100%;"
          @click="filterModalOk(0)"
          :loading="isLoadingModal"
        >{{ $t("search_funds.add_all") }}
        </sdButton>
      </a-col>
      <a-col :xs="24">
        <sdButton
          type="primary"
          class="mt-2"
          style="width: 100%;"
          @click="filterModalOk(1)"
          >{{ $t("search_funds.change_criteria") }}
        </sdButton>
      </a-col>
    </a-row>  
  </a-modal>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { useFundsStore } from "@/stores/FundsStore";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useBasketStore } from "@/stores/BasketStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const fundsStore = useFundsStore();

const props = defineProps({
  funds: {
    type: Array,
    default: function () {
      return [];
    },
  },
  modelValue: {
    type: Boolean,
    default: function () {
      return false;
    },
  },
  searcher: {
    type: Function,
    required: true,
  },
  hasRename: {
    type: Boolean,
    default: function () {
      return false;
    },
  },
});
const emit = defineEmits(["update:modelValue", "changeCriteria"]);

const isLoadingModal = ref(false);

const value = useModelWrapper(props, emit);
const basketStore = useBasketStore();
const isLoadingRename = ref(false);
const searchName = ref('');

const saveSearch  = () => {
  if (!searchName.value || searchName.value.trim() === '') {
    message.warning(t('search_funds.save_search_empty_name')); 
    return;
  }

  if (fundsStore.saved_searches.find(e => e.search === searchName.value)) {
    message.warning(t('search_funds.save_search_error'));
  } else {
    isLoadingRename.value = true;
    fundsStore.saveSearch(searchName.value).then(() => {
      isLoadingRename.value = false;
      searchName.value = '';
    }).catch(err => {
      isLoadingRename.value = false;
      console.log("Error: ", err);
    });
  }
};

const filterModalOk = async (option) => {
  if (option === 0) {
    isLoadingModal.value = true;
    const isinArray = props.funds.map((fund) => fund.isin_tkr_id);
    try {
      const result = await props.searcher(isinArray);

      if (result && Array.isArray(result)) {
        await basketStore.addAsset(result);
        message.success(t("funds_printer.added_funds"));
        const element = document.getElementById('basket-table-part');
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    } catch (err) {
      console.log(err);
      message.error(t("generic.titles.error"));
    } finally {
      isLoadingModal.value = false;
      value.value = false;
    }
  } else {
    value.value = false;
    emit("changeCriteria");
  }
};
</script>
<style lang="scss">
.founds-modal {
  & .ant-modal-content {
    padding: 2.5rem 6rem;
    text-align: center;
    & .ant-modal-header {
      border: none;
      padding: 0;
      .ant-modal-title {
        font-weight: 700;
      }
    }
    & .ant-modal-body {
      padding: 0;
      & .ant-select {
        padding: 0;
      }
    }
    & .ant-modal-footer {
      border: none;
      padding: 0;
      padding-top: 1rem;
      button {
        width: 100%;
      }
    }
  }
}
.search-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0;
}
@media only screen and (max-width: 768px) {
  .bests-modal {
    width: 75%;
    & .ant-modal-content {
      padding: 2rem;
    }
  }  
}
</style>