<template>
    <Main class="general-background">
      <div class="search-card">
        <a-col :xs="24" :md="16" class="search-tabs">
          <div
            class="search-tab-item"
            v-for="item in searchTabs"
            :key="item.id"
            @click="changeSearchTab(item.id)"
            :class="{ 'active': activeSearch === item.id, 'borde-r': item.id !== SearchCardItems.CUSTOM_SEARCH }"
          >
            <p>{{ $t(item.label) }}</p>
          </div>
        </a-col>
        <div class="mobile-search-option">
          <a-select
            mode="single"
            v-model:value="activeSearch"
            class="native-select"
            size="small"
            :filterOption="customFilter"
            :defaultActiveFirstOption="true"
          >
            <a-select-option
              v-for="item in searchTabs"
              :key="item.id"
              :value="item.id"
              :title="item.id"
            >
              {{ $t(item.label) }}
            </a-select-option>
          </a-select>
        </div>
        <a-row>
          <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['SearchByName']) && activeSearch === SearchCardItems.BY_FUND_OR_ISIN">
            <asset-by-ticker
              v-model="isinTkr"
              @selectedTkrs="updateTkrs"
              @blur="resetTicker"
              tickerType="etfs"
              @selectedSearch="populate"
              :loading="isLoading"
              :auto-filter="false"
            ></asset-by-ticker>
          </a-col>
          <a-col :xs="24" :md="16" v-if="!authStore.restrictions.includes(Restrictions['ChooseCriteria']) && activeSearch === SearchCardItems.CUSTOM_SEARCH">
            <a-modal
              :visible="activeSearch === SearchCardItems.CUSTOM_SEARCH"
              class="custom-search-modal"
              type="primary"
              :footer="null"
              @cancel="handleCancel"
            >
              <EtfCustomSearch 
                :funds="etfStore"
                @populate="populateFiltering"
                :isLoading="isLoading"
              />
            </a-modal>
          </a-col>
        </a-row>
      </div>
      <basket-card ref="fundsTable" assetType="master_funds_columns">
      </basket-card>
      <founds-modal
        v-model="filterModalVisible"
        :funds="foundResults"
        :searcher="etfStore.searchEtfsByIsinTkr"
        @change-criteria="onChangeCriteria"
      >
      </founds-modal>
    </Main>
</template>
<script setup>
import { Main } from "../styled";
import { ref, watch, onMounted } from "vue";
import EtfCustomSearch from "../../components/wiseral/filters/EtfCustomSearch.vue";
import AssetByTicker from "../../components/wiseral/searchAnalysis/AssetByTicker.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useEtfsStore } from "@/stores/EtfsStore";
import { useBasketStore } from "@/stores/BasketStore";
import BasketCard from "@/components/wiseral/basket/BasketCard.vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import FoundsModal from "@/components/wiseral/FoundsModal";
import SearchCardItems from "@/config/search/cardIDs";
import Restrictions from "@/config/restriction/restrict_functions";

  const router = useRouter();

const { t } = useI18n();

const authStore = useAuthStore();
const etfStore = useEtfsStore();
const basketStore = useBasketStore();
const isLoading = ref(false);
const filterModalVisible = ref(false);
const activeSearch = ref(SearchCardItems.BY_FUND_OR_ISIN);

const isinTkr = ref([]);
const searchTabs = [
  {
    id: SearchCardItems.BY_FUND_OR_ISIN,
    label: 'search_funds.search_asset'
  },
  {
    id: SearchCardItems.CUSTOM_SEARCH,
    label: 'search_funds.select_filter_criteria'
  }
];

const changeSearchTab = (tabKey) => {
    activeSearch.value = tabKey;
};

const handleCancel = () => {
    activeSearch.value = SearchCardItems.BY_FUND_OR_ISIN;
};

const populate = async () => {
    isLoading.value = true;
    try {
      const result = await etfStore.searchEtfs();
      if (result && Array.isArray(result)) {
        await basketStore.addAsset(result);
        message.success(t("funds_printer.added_funds"));
      }
    } catch (err) {
      console.log(err);
    } finally {
      isLoading.value = false;
    }
};

const foundResults = ref();

const populateFiltering = async () => {
    isLoading.value = true;
    try {
      const result = await etfStore.searchEtfsFiltering();
      if (result && Array.isArray(result)) {
        foundResults.value = result;
        filterModalVisible.value = true;
      }
    } catch (err) {
      console.log(err);
      message.error(t("generic.titles.error"));
    } finally {
      isLoading.value = false;
      handleCancel();
    }
};

const updateTkrs = async (e) => {
    authStore.logAction('add_asset');
    e.map((f) => {
      basketStore.addAssetById(e.type, f.id);
    });
};

const resetTicker = () => {
    isinTkr.value = etfStore.data.isin;
};

const onChangeCriteria = () => {
  activeSearch.value = SearchCardItems.CUSTOM_SEARCH;
};

watch(
    () => isinTkr.value,
    () => {
      etfStore.data.isin = isinTkr.value.filter((e) => typeof e === "number");
    }
);

onMounted(async () => {
    etfStore.resetData();
    const path = router.currentRoute.value.path;
    await basketStore.changeBasketUrl(path);
});
</script>
<style scoped lang="scss">
.general-background {
    background-color: #F2F2F2;
}
.mobile-gap {
    & > * {
      margin: 10px 0;
    }
}
.native-select.modal-select {
    .ant-select-selector {
      border: 1px #000 solid;
      padding: 0px 10px !important;
      margin: 0 !important;
      height: 45px;
    }
    .ant-select-arrow {
      margin-right: 10px;
    }
}
.search-card {
    border: 1px solid #02B388;
    padding: 36px !important;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 7.5rem;
}
.search-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 16px;
    font-size: 14px;
    & .search-tab-item {
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #004851;
        color: white;
      }
      &:hover {
        background-color: #004851;
        color: white;
      }
      &.borde-r {
        border-right: 1px solid black;
      }
      & p {
        padding-top: 12px;
        padding-bottom: 12px;
        margin: 0 !important;
      }
    }
}
.mobile-search-option {
    display: none;
}
@media only screen and (max-width: 768px) {
    .search-tabs {
      display: none;
    }
    .mobile-search-option {
      display: block;
      margin-bottom: 16px;
      & .ant-select {
        padding: 0 !important;
        height: 45px !important;
        .ant-select-selector {
          height: 100% !important;
        }
      }
    }
}
</style>

<style>
.custom-search-modal {
    width: 90% !important;
}
@media only screen and (min-width: 768px) {
    .custom-search-modal {
      width: 70% !important;
    }
}
</style>